@font-face {
  font-family: "webfont";
  src: url("./fonts/OpenSans/OpenSans-Medium.ttf") format("truetype");
}
body{
  font-family: 'webfont', Fallback, sans-serif;
  margin: 0;
}
header{
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%);
}
header img{
  width: 215px;
  padding: 12px;
}
.boxii{
  padding: 0 75px;
}
footer{
  padding: 24px;
  color: #fff;
  background: #005ca3;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  header img {
    width: 150px;
    padding: 8px;
  }
  .boxii{
    padding: 0 16px;
  }
  .boxii ul{
    padding-left: 16px;
  }
  .boxii h1{
    font-size: 26px;
  }
  footer {
    padding: 16px;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
